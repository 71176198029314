import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Palette } from '@api/appdata/model';
import type { BrandID, BrandSummary, PackageID, BrandDetail, PlatformID } from '@api/cas/models';
import { Product, ProductGroup } from '@api/vpapi/models';
import { BrandManagementRedirect } from '@components';
import { Optional } from './types';
import { ProductJSON } from '@api/vpapi/models/Product';

type PathSource = 'brand-dashboard' | 'package-dashboard' | 'product-group-access';
export type BrandSelectionModalLocationState = {
  brands?: Array<BrandSummary>;
  redirectType: PathSource;
};

export default {
  platformDetail(id: PlatformID) {
    return `/apps/${id}`;
  },

  editProduct(p: Product) {
    const groupID = p.productGroupID();
    const productID = p.dbid;

    return `/admin/product-groups/${groupID}/products/edit/${productID}`;
  },

  bulkEdit(id: string) {
    return `/admin/product-groups/${id}/edit`;
  },

  userProfile() {
    return '/account/profile';
  },
  productGroups() {
    return '/admin/product-groups';
  },
  productImport(productGroupID: string) {
    return `/admin/product-groups/${productGroupID}/import`;
  },

  products(productGroupID: string) {
    return `/admin/product-groups/${productGroupID}`;
  },

  productGroupCreation() {
    return '/product-groups/_create';
  },
  productGroupAccess(brandID: BrandID) {
    return `/product-group-access/${brandID}`;
  },
  productGroupSettings(productGroupID: string): string {
    return `/admin/product-groups/${productGroupID}/details`;
  },
  productGroupStaging(productGroup: ProductGroup, products: ProductJSON[] | Product[], title: string) {
    return {
      hash: '',
      pathname: `/admin/product-groups/${productGroup.dbid}/staging`,
      state: {
        productGroup: JSON.stringify(productGroup),
        products: JSON.stringify(products),
        stagingTitle: title,
      },
    };
  },

  productGroupWithID(groupID: string) {
    return `/admin/product-groups/${groupID}`;
  },

  scanDetails(scanID: string) {
    return `/scans/${scanID}`;
  },
  savedColor(paletteID: string, itemID: string) {
    return `/saved-colors/${paletteID}/items/${itemID}`;
  },

  savedFolderCreator() {
    return '/saved-colors/_create';
  },

  savedColors(palette?: Palette) {
    if (!palette) {
      return '/saved-colors';
    }

    return `/saved-colors/${palette.uuid}`;
  },
  paletteDetails(palette: Palette) {
    return `/saved-colors/${palette.uuid}/details`;
  },

  productVizualization(productGroupID: string) {
    return `/product-groups/${productGroupID}/visualize`;
  },
  packageDashboard(brandID?: BrandID, packageID?: PackageID, brand: Optional<BrandDetail> = undefined) {
    let state;
    if (brand) {
      state = { brand };
    }

    if (brandID && packageID) {
      return {
        hash: '',
        pathname: `/packages/${brandID}/${packageID}`,
        state,
      };
    }

    if (brandID && !packageID) {
      return {
        hash: '',
        pathname: `/packages/${brandID}`,
        state,
      };
    }

    if (!brandID && packageID) {
      console.error('This is a bad parameter state, should be impossible');
    }

    return this.brandSelection('package-dashboard');
  },
  brandSelection(redirectType: PathSource, brands?: BrandSummary[]) {
    return {
      hash: '',
      pathname: '/brands',
      state: {
        brands,
        redirectType,
      },
    };
  },
  brandDashboard(brandID: BrandID) {
    return `/brands/${brandID}/dashboard`;
  },

  organizationUserProfile(email: string) {
    return `/users?id=${encodeURIComponent(email)}`;
  },
  brandModal(brandID?: string) {
    if (brandID) {
      return `/brands/${brandID}`;
    }

    return '/brand/_create';
  },

  subscribeUser(brandID: string, email?: string) {
    if (email) {
      return `/brands/${brandID}/dashboard/_subscribe?email=${encodeURIComponent(email)}`;
    }

    return `/brands/${brandID}/dashboard/_subscribe`;
  },

  useBrandModalNavigationCallbacks() {
    const {
      state,
    }: {
      readonly state?: BrandSelectionModalLocationState;
    } = useLocation();
    const history = useHistory();
    return {
      createBrand: React.useCallback(() => history.push(this.brandModal()), [history]),
      selectBrand: React.useCallback(
        ({ id }: { id: string }) => {
          sessionStorage.setItem(BrandManagementRedirect.SELECTED_BRAND_ID_KEY, id);

          if (!state || state.redirectType === 'brand-dashboard') {
            // user used a direct link to this page.
            //// navigate them to view the organization.
            history.replace(this.brandDashboard(id));
          } else if (state.redirectType === 'package-dashboard') {
            history.replace(this.packageDashboard(id));
          } else if (state.redirectType === 'product-group-access') {
            history.replace(this.productGroupAccess(id));
          }
        },
        [history, state],
      ),
    };
  },
};
