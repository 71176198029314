import * as React from 'react';

import { FlexColumns } from '..';
import { Button } from '../Buttons';
import { IconInput } from './Input';
import theme from '@theme';

export type Props = {
  handleSubmit: (name: string) => void | any;
  handleCancel: () => void;
};

type RootViewProps = Props & {
  placeholder: string;
  buttons: {
    positiveText: string;
    negativeText: string;
  };
};

type ViewState = {
  name: string;
};
class AddWithName extends React.Component<RootViewProps, ViewState> {
  state = {
    name: '',
  };

  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.setState({ name: value });
  };

  handleSubmit = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    let { name } = this.state;
    name = name.trim();
    const { handleSubmit } = this.props;

    if (name.length > 2) {
      if (handleSubmit) {
        handleSubmit(name);
      }
    }
  };

  handleCancel = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    const { handleCancel } = this.props;
    if (handleCancel) {
      handleCancel();
    }
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    this.handleSubmit(event);
  };

  render = () => {
    const { placeholder, buttons } = this.props;
    const { name } = this.state;

    return (
      <div>
        <IconInput
          inputProps={{
            autoFocus: true,
            onKeyPress: this.handleKeyPress,
            placeholder,
            type: 'text',
            value: name,
          }}
          name="info"
          onChange={this.handleChange}
        />

        <div style={{ marginTop: 18 }} />

        <FlexColumns style={{ justifyContent: 'flex-end' }}>
          <Button
            padding={10}
            paddingLeft={18}
            paddingRight={18}
            text={buttons.positiveText}
            color={theme.button.createColor}
            onClick={this.handleSubmit}
          />

          <Button
            padding={10}
            paddingLeft={18}
            paddingRight={18}
            text={buttons.negativeText}
            color={theme.button.cancelColor}
            outline
            onClick={this.handleCancel}
          />
        </FlexColumns>

        <div style={{ padding: 8 }} />
      </div>
    );
  };
}

const AddProductGroup = (props: Props) =>
  React.cloneElement(
    <AddWithName
      placeholder="Enter new Product Library name"
      buttons={{
        negativeText: 'Cancel',
        positiveText: 'Create Product Library',
      }}
      {...props}
    />,
  );

const AddPlatformView = (props: Props) =>
  React.cloneElement(
    <AddWithName
      placeholder="Enter new app name"
      buttons={{
        negativeText: 'Cancel',
        positiveText: 'Create App',
      }}
      {...props}
    />,
  );

const AddPackageView = (props: Props) =>
  React.cloneElement(
    <AddWithName
      placeholder="Enter a App Package name"
      buttons={{
        negativeText: 'Cancel',
        positiveText: 'Create',
      }}
      {...props}
    />,
  );

const AddCustomerView = (props: Props) =>
  React.cloneElement(
    <AddWithName
      placeholder="Enter a customer name"
      buttons={{
        negativeText: 'Cancel',
        positiveText: 'Create',
      }}
      {...props}
    />,
  );

const LegacyLibraryInputView = (props: Props) =>
  React.cloneElement(
    <AddWithName
      placeholder="Enter a legacy product library id"
      buttons={{
        negativeText: 'Cancel',
        positiveText: 'Import',
      }}
      {...props}
    />,
  );
export { AddPackageView, AddProductGroup, AddPlatformView, LegacyLibraryInputView, AddCustomerView };
