import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import cas from '../api/cas';
import User from '../api/cas/user';
import api2 from '../api/fetch2';

import Main from '../Main';

import LeftMenu from '../components/nav/LeftMenu';

import routes from '../utils/route-utils';

import { routes as router, routableNavigation } from '../routes';
import { Container, MainContent, VariableCloudPromoNotice } from './components';
import { useAppEffects } from './useAppEffects';
import { Optional } from '@app/utils/types';

export default function App() {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = React.useState<Optional<User>>(User.load());
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const handleLogout = React.useCallback(() => {
    cas.users
      .logout()
      .then(() => {
        setUser(undefined);
      })
      .catch(() => {
        setUser(undefined);
      });
  }, [setUser]);

  const onAuth = React.useCallback(
    (u: User) => {
      setUser(u);

      switch (location.pathname) {
        case '/reset-password':
        case '/register':
        case '/login':
          history.replace(routes.userProfile());
          return;
      }
    },
    [history, location],
  );

  api2.onSessionInvalidCallback = React.useCallback(() => {
    setUser(undefined);
  }, [setUser]);

  useAppEffects(user, forceUpdate);

  if (user?.isAuthed === true) {
    return (
      <Container>
        <LeftMenu
          system={routableNavigation.system(user)}
          general={routableNavigation.general()}
          tools={routableNavigation.tools()}
          account={routableNavigation.account(handleLogout)}
        />
        <MainContent>
          <div>
            <VariableCloudPromoNotice user={user} />
            <Main onAuth={onAuth} />
          </div>
        </MainContent>
      </Container>
    );
  }
  return <router.UnauthedRouter onAuth={onAuth} />;
}
