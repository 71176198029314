import * as React from 'react';
import { RouteProps } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
// import MetaTags from 'react-meta-tags';
import Home from '@components/Home';
import PalettesPage from '@components/palettes/PalettesPage';
import ProductImportInstructions, { AboutProducts } from '@components/products/import/ProductImportInstructions';
import ProductGroupAccess from '@components/productGroupsAccess/ProductGroupAccess';
import {
  EditProduct,
  EmailValidation,
  PackageDashboard,
  PlatformDetail,
  ProductGroups,
  PurchaseLoader,
  Users,
} from '@components';
import { withUser, withUserViewProps, SystemAdminOnly } from '@components/common';
import User from '@api/cas/user';
import config from './config';

const PlatformCreator = React.lazy(() => import('./components/platforms/PlatformCreator'));
const BrandSelectionModal = React.lazy(() => import('./components/brands/BrandSelectionModal'));
const BrandDashboard = React.lazy(() => import('./components/brands/BrandDashboard'));
const ColorCalculator = React.lazy(() => import('./components/tools/calculator/ColorCalculator'));
const ComparisonCalculator = React.lazy(() => import('./components/tools/calculator/ComparisonCalculator'));
const ProductGroupDetail = React.lazy(() => import('./components/productGroups/ProductGroupDetail'));
const ResetPassword = React.lazy(() => import('./components/account/ResetPassword'));
const Platforms = React.lazy(() => import('./components/platforms/Platforms'));
const Profile = React.lazy(() => import('./components/account/Profile'));
const ProductPlotter = React.lazy(() => import('./components/products/ProductPlotter'));
const PackageSelectionModal = React.lazy(() => import('./components/package-dashboard/PackageSelectionModal'));
const BrandCreator = React.lazy(() => import('./components/brands/BrandCreator'));
const ProductGroupCreationModal = React.lazy(() => import('./components/productGroups/ProductGroupCreationModal'));
const ProductImportView = React.lazy(() => import('./components/products/import/ProductImportView'));
const ScanAnalytics = React.lazy(() => import('./components/analytics/ScanAnalytics'));

type ExternalProps = {
  onAuth: (u: User) => any;
};
type ViewProps = ExternalProps & withUserViewProps;

function AdminRoutes(user: User) {
  let routes = [] as JSX.Element[];
  if (!user) {
    return routes;
  }

  if (user.is_system_admin) {
    routes = routes.concat([
      <Route
        exact
        path={['/product-group-access', '/product-group-access/:brandID']}
        component={ProductGroupAccess}
        key="product-group-access"
      />,

      <Route path="/admin/scan/analytics" component={ScanAnalytics} key="scan-analytics" />,
    ]);
  }

  return routes;
}

type MetaTags = { title?: string; name?: string; content?: any };
type RouteWithMetaProps = RouteProps & {
  tags: MetaTags[];
};

function RouteWithTags({ tags, children, component: RouteComponent, ...routeProps }: RouteWithMetaProps) {
  console.log(tags);
  let Component;
  if (RouteComponent && !children) {
    Component = withRouter(RouteComponent);
  } else if (children) {
    Component = () => <>{children}</>;
  } else {
    return null;
  }

  return (
    <Route {...routeProps}>
      {/* <MetaTags>
        {tags.map((t: MetaTags) => {
          if (t.title) {
            return <title key={t.title}>{t.title}</title>;
          }
          return <meta key={t.name} {...t} />;
        })}
      </MetaTags> */}
      <Component />
    </Route>
  );
}

function VariableCloudAccessRoutes(user: User) {
  return [
    <Route exact path="/packages/:brandID" component={PackageSelectionModal} key="package-selector" />,

    <Route path={['/brands/:brandID/dashboard']} key="brands-dashboard">
      <BrandDashboard user={user} />
    </Route>,

    <Route exact path={['/brands/:brandID', '/brand/_create']} component={BrandCreator} key="brand-editor" />,

    <Route
      exact
      path="/admin/product-groups/:productGroupID/staging"
      component={ProductImportView}
      key="create-products-staging"
    />,

    <Route exact path="/product-groups/_create" component={ProductGroupCreationModal} key="product-group-creator" />,

    <Route
      exact
      path="/admin/product-groups/:productGroupID/products/edit/:productID"
      component={EditProduct}
      key="admin-product-edit"
    />,
    <RouteWithTags
      tags={[
        { title: 'Library Manager' },
        {
          content: 'Manage product libraries online, and get started today',
          name: 'description',
        },
      ]}
      exact
      path="/admin/product-groups/"
      key="product-groups-list_"
    >
      <ProductGroups user={user} />
    </RouteWithTags>,
  ];
}

function Routes({ user }: ViewProps) {
  return [...AdminRoutes(user), ...VariableCloudAccessRoutes(user)];
}

function FallbackUI() {
  return <div>Failed to load content, please refresh your page</div>;
}
export default withUser<ExternalProps>(({ onAuth, user }: ViewProps) => {
  return (
    <React.Suspense fallback={FallbackUI()}>
      <Switch>
        {Routes({ onAuth, user })}
        <Route exact path="/users" key="organization-user">
          <Users />
        </Route>
        <Route exact path="/help/products/import" key="import_help">
          <ProductImportInstructions />
        </Route>
        <Route exact path="/help/products/about" key="about_products">
          <AboutProducts />
        </Route>
        <Route path={['/saved-colors/:paletteID', '/saved-colors', '/scans']} key="saved-colors">
          <PalettesPage user={user} />
        </Route>
        <Route exact path={['/packages/:brandID/:packageID', '/packages']} key="packages-dashboard">
          <PackageDashboard user={user} />
        </Route>
        <Route exact path="/brands" key="brand-selection">
          <BrandSelectionModal user={user} />
        </Route>
        <RouteWithTags
          tags={[
            {
              content: 'Acccount management for Variable Cloud and mobile apps powered by Variable Cloud.',
              name: 'description',
            },
            { title: 'Variable Cloud - Account Management' },
          ]}
          exact
          path="/account/profile"
          key="user-profile"
        >
          <Profile user={user} />
        </RouteWithTags>
        <RouteWithTags
          tags={[
            {
              content: 'Calculator for converting CIE Lab values into other color spaces',
              name: 'description',
            },
            {
              title: 'Variable Cloud Color Calculator',
            },
          ]}
          path="/calculator"
          key="color-calculator"
        >
          <ColorCalculator />
        </RouteWithTags>
        ,
        <Route path="/color-compare" key="color-compare">
          <ComparisonCalculator />
        </Route>
        ,
        <Route exact path="/account/profile/purchases" key="user-purchases">
          <PurchaseLoader />
        </Route>
        <Route path="/account/verify-email" key="account-verification">
          <EmailValidation />
        </Route>
        <Route exact path="/" key="support">
          <Home onAuth={onAuth} />
        </Route>
        <Route
          exact
          path="/contact"
          key="contact"
          component={() => {
            window.location.href = config.request_form_url;
            return null;
          }}
        />
        <Route exact path="/account/reset-password" key="account-pw-reset">
          <ResetPassword />
        </Route>
        <Route path="/admin/product-groups/:productGroupID" key="admin-product-group-detail-2">
          <ProductGroupDetail user={user} />
        </Route>
        <Route path="/product-groups/:productGroupID" key="admin-product-group-detail">
          <ProductGroupDetail user={user} />
        </Route>
        <SystemAdminOnly>
          <>
            <Route path="/admin/users" key="users">
              <Users />
            </Route>
            <Route exact path="/apps/_create">
              <PlatformCreator />
            </Route>
            <Route exact path={['/sdks/:platformID', '/apps/:platformID']} key="sdk-platform-details">
              <PlatformDetail />
            </Route>

            <Route exact path="/apps" key="apps">
              <Platforms />
            </Route>

            <Route path="/admin/products-chart" key="product-plotter">
              <ProductPlotter />
            </Route>
          </>
        </SystemAdminOnly>
      </Switch>
    </React.Suspense>
  );
});
