import * as React from 'react';

import theme from '@theme';
import { Product, ProductCompositionDetail } from '@api/vpapi/models';
import EditCompositionDetail from './EditCompositionDetail';

import ModuleSegment from '@components/common/ModuleSegment';
import CompositionPreviewList from './CompositionPreviewList';
import { Button, withUser, withUserViewProps } from '@components/common';
import AddColorModal from './AddColorModal';
import { Optional } from '@app/utils/types';

type ExternalProps = {
  disable_for_ecd?: boolean;

  product: Product;
  onProductChange?: () => void | any;
};
type ViewProps = withUserViewProps & ExternalProps;

type ViewState = {
  selectedComposition?: ProductCompositionDetail;
};
class CompositionContainer extends React.Component<ViewProps, ViewState> {
  static getDerivedStateFromProps = (nextProps: ViewProps, prevState: ViewState): ViewState => {
    if (!prevState.selectedComposition) {
      const { composition_details } = nextProps.product;
      if (composition_details && composition_details.length > 0) {
        return {
          selectedComposition: composition_details[0],
        };
      }
    }

    return prevState;
  };

  state = {
    selectedComposition: undefined as Optional<ProductCompositionDetail>,
  };

  get DeleteSelectedColorButton() {
    const { selectedComposition } = this.state;
    if (!selectedComposition) {
      return null;
    }

    return (
      <Button
        key="delete_product_color"
        color={theme.button.cancelColor}
        onClick={this.handleDeleteComposition}
        text="Delete Color"
      />
    );
  }

  get AddColorButton() {
    const TriggerButton = (
      <Button outline color={theme.button.createColor} onClick={this.addNewProductColor} text="Add Color" />
    );

    return (
      <AddColorModal
        key="add_product_color_modal"
        composition={this.addNewProductColor()}
        trigger={TriggerButton}
        handleSubmit={this.handleAddComposition}
      />
    );
  }
  get moduleButtons() {
    const buttons: React.ReactElement[] = [];
    const { product, onProductChange } = this.props;
    if (!onProductChange) {
      return buttons;
    }

    if (!product.limit_product_colors) {
      buttons.push(this.AddColorButton);
    }

    if (this.DeleteSelectedColorButton) {
      buttons.push(this.DeleteSelectedColorButton);
    }

    return buttons;
  }

  CompositionDetail = () => {
    const { selectedComposition } = this.state;
    if (!selectedComposition) {
      return null;
    }

    const { onProductChange, product, disable_for_ecd } = this.props;
    return (
      <>
        <CompositionPreviewList
          selected={selectedComposition}
          compositions={product.composition_details}
          handleClick={this.handleSelection}
        />

        <EditCompositionDetail
          disable_color_inputs={disable_for_ecd}
          composition={selectedComposition}
          key={selectedComposition.id}
          onProductChange={onProductChange}
          showManualPreview={false}
        />
      </>
    );
  };

  render = () => {
    const { CompositionDetail } = this;
    return (
      <ModuleSegment
        containerStyle={{ height: '100%', maxWidth: 1500, width: '100%' }}
        title="Product Colors"
        buttons={this.moduleButtons}
      >
        <CompositionDetail />
      </ModuleSegment>
    );
  };

  handleSelection = (selectedComposition: ProductCompositionDetail) => {
    this.setState({
      selectedComposition,
    });
  };

  handleAddComposition = (composition: ProductCompositionDetail) => {
    this.props.product.composition_details.push(composition);
    this.props.onProductChange?.();
  };

  handleDeleteComposition = () => {
    const { selectedComposition } = this.state;
    if (!selectedComposition) {
      console.log('Attempt to delete invalid color');
      return;
    }
    const { product, onProductChange } = this.props;
    product.composition_details = product.composition_details.filter(_cd => _cd.id !== selectedComposition.id);

    this.setState(
      {
        selectedComposition: product.composition_details[0],
      },
      onProductChange,
    );
  };

  addNewProductColor = () => {
    const {
      user: { email },
    } = this.props;
    const cd = ProductCompositionDetail.NewFromLab({
      d50L: 0,
      d50a: 0,
      d50b: 0,
    });
    cd.entered_by = email;

    return cd;
  };
}

export default withUser<ExternalProps>(props => <CompositionContainer {...props} />);
