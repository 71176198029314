import User from '@api/cas/user';
export { default as routes } from './router';
type RoutableNavigationItemProps = {
  name: string;
  icon: string;
  path?: string;
  onClick?: () => void | any;
};

export const routableNavigation = {
  account(onLogout: () => void): RoutableNavigationItemProps[] {
    return [
      {
        icon: '/profile.svg',
        name: 'Account',
        path: '/account/profile',
      },
      {
        icon: '/saved_colors.svg',
        name: 'Saved Colors',
        path: '/saved-colors',
      },
      {
        icon: '/icons/logout.png',
        name: 'Logout',
        onClick: onLogout,
        path: undefined,
      },
    ];
  },

  system(user?: User): RoutableNavigationItemProps[] {
    if (!user || !user.is_system_admin) {
      return [];
    }

    return [
      {
        icon: '/icons/access.png',
        name: 'Product Access',
        path: '/product-group-access',
      },
      { icon: '/icons/app.png', name: 'Apps', path: '/apps' },
      { icon: '/icons/sdk.png', name: 'Mobile SDK', path: '/sdks/xsd4eq2kjl0' },
      { icon: '/icons/access.png', name: 'Users', path: '/admin/users' },
    ];
  },
  general(/** user: User  */): RoutableNavigationItemProps[] {
    return [
      {
        icon: '/brand.svg',
        name: 'Organization',
        path: '/brands',
      },
      {
        icon: '/product_libraries.svg',
        name: 'Library Manager',
        path: '/admin/product-groups/',
      },
      {
        icon: '/images/packages.svg',
        name: 'App Packages',
        path: '/packages',
      },
    ];
  },
  tools(/**user: User**/): RoutableNavigationItemProps[] {
    return [
      {
        icon: '/icons/compare.png',
        name: 'Compare',
        path: '/color-compare',
      },
      {
        icon: '/icons/calculator.png',
        name: 'Calculator',
        path: '/calculator',
      },
    ];
  },
};
