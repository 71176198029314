import React from 'react';

import { ModuleSegment, LabeledInput, Button, FlexColumns, Flash, FlashContentT } from '../../common';

import { Feature, RequiredFieldsFeatureOptions, UserDefinedField } from '@api/cas/models';
import theme from '@theme';
import { Optional } from '@app/utils/types';

interface Props {
  feature: Feature<RequiredFieldsFeatureOptions>;

  onDelete<T>(f: Feature<T>): void;
  onCancel: () => void;
  onSubmit<T>(f: Feature<T>): void;
}

export default function FeatureEditor(props: Props) {
  const { flash, onSubmitCallback, onDeleteCallback, renderInputs } = useFeatureEditor(props);
  return (
    <ModuleSegment title={ModuleSegment.ModuleTitle('Feature Editor', 'Please complete the following fields')}>
      <Flash content={flash} />
      {renderInputs()}
      <FlexColumns style={{ justifyContent: 'flex-end' }}>
        <Button onClick={onDeleteCallback} color={theme.button.cancelColor} text="Clear Feature" />
        <Button onClick={onSubmitCallback} color={theme.button.createColor} text="Create Feature" />
        <Button onClick={props.onCancel} outline color={theme.button.cancelColor} text="Cancel" />
      </FlexColumns>
    </ModuleSegment>
  );
}

function getActiveValueForFeature(feature: Feature<RequiredFieldsFeatureOptions>, field: UserDefinedField) {
  return (feature.options as any)[field.id];
}
function useFeatureEditor({ onSubmit, feature, onDelete }: Props) {
  const [flash, setFlash] = React.useState<Optional<FlashContentT>>();
  const fields = feature.options?.user_defined_fields;
  const [userInputState, setUserInput] = React.useState(fields.reduce((prev, x) => ({ ...prev, [x.id]: '' }), {}));

  return {
    flash,
    setFlash,
    setUserInput,

    renderInputs() {
      return fields.map(field => (
        <LabeledInput
          label={field.name}
          key={field.id}
          inputProps={{ placeholder: field.placeholder, value: getActiveValueForFeature(feature, field) }}
          onChange={(_e, { value }) => {
            setUserInput({ ...userInputState, [field.id]: value });
          }}
        />
      ));
    },

    onDeleteCallback: React.useCallback(() => onDelete(feature), [onDelete, feature]),

    onSubmitCallback: React.useCallback(() => {
      const requiredFieldNotPresent = fields.find(x => x.is_required && (userInputState as any)[x.id] === '');
      if (requiredFieldNotPresent) {
        setFlash({
          message: `Missing value for ${requiredFieldNotPresent.name}`,
          color: 'red',
        });
        return;
      }
      onSubmit({
        id: feature.id,
        name: feature.id,
        options: { ...feature.options, ...(userInputState as any) },
      });
    }, [feature, onSubmit, userInputState, fields]),
  };
}
