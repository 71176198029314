import React from 'react';
import styled from 'styled-components';
import { Header } from '../Text';
import theme from '@theme';

type ViewProps = {
  onClick: () => void | any;
  iconSrc?: string;
  text: string;
  css?: {
    filter: string;
  };

  color?: string;
};

const InlineIcon = styled.div`
  height: 96px;
  width: 96px;
`;

const I = styled.img<{ css: any }>`
  height: 75%;
  width: 75%;

  object-fit: contain;

  ${props => props.css}
`;

const Container = styled.div`
  background-color: ${theme.table.rowBackground};
  height: 192px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  margin-top: 24px;

  cursor: pointer;

  :hover {
    font-size: 110%;
    font-weight: bold;

    background-color: ${theme.table.hoverBackground};

    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  }
`;
export default function LargeButton({ color, onClick, iconSrc, text, css }: ViewProps) {
  return (
    <Container onClick={onClick} color={color}>
      <Header>{text}</Header>
      {iconSrc && (
        <InlineIcon>
          <I src={iconSrc} css={css} />
        </InlineIcon>
      )}
    </Container>
  );
}
