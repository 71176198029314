import * as React from 'react';

import { Button, DropdownProps, FlexColumns } from '@components/common';
import { ModuleSegment } from '@components/common';
import { Feature, PlanOptions, VariableCloudFeatureOptions } from '@api/cas/models';
import PaidSubscriptionOptions from './PaidSubscriptionOptions';

import cas from '@api/cas';
import theme from '@app/theme';
import { Optional } from '@app/utils/types';
import moment from 'moment';

function useVariableCloudFeature(defaultUserFeature?: Feature<VariableCloudFeatureOptions>) {
  const [plans, setPlans] = React.useState<PlanOptions[]>([]);

  React.useEffect(() => {
    if (plans.length === 0) {
      async function fetchPlans() {
        const { plans: ps } = await cas.fetchVariableCloudSubscriptionPlans();
        setPlans(ps);
      }

      fetchPlans();
    }
  }, [plans.length]);

  const [userFeature, setUserFeature] =
    React.useState<Optional<Feature<VariableCloudFeatureOptions>>>(defaultUserFeature);
  return {
    handleFeatureCheckChange:
      (sub: 'bulk_edit' | 'vizualizations' | 'package_codes') => (_e: React.SyntheticEvent, isChecked: boolean) => {
        setUserFeature(prev => {
          if (prev) {
            prev.options.plan.features[sub] = isChecked;
            return { ...prev };
          }
        });
      },

    handleAccessCheckChange:
      (sub: 'organizations' | 'product_groups') => (_e: React.SyntheticEvent, isChecked: boolean) => {
        setUserFeature((prev: Optional<Feature<VariableCloudFeatureOptions>>) => {
          if (prev) {
            prev.options.plan.access_control[sub] = isChecked;
            return { ...prev };
          }
        });
      },

    onPlanChange: (_e: React.SyntheticEvent, { value }: DropdownProps) => {
      setUserFeature(prev => {
        const expires_at = new Date();
        expires_at.setMonth(expires_at.getMonth() + 1);

        return {
          id: 'com.variable.features.VARIABLE_CLOUD',
          name: 'Variable Cloud Subscription',
          options: {
            plan: plans.find(p => p.id === value)!,
            redemption_code: prev?.options?.redemption_code ?? 'ADMIN_OVERRIDE',
            activated_at: prev?.options?.activated_at ?? new Date().toISOString(),
            expires_at: prev?.options?.expires_at ?? expires_at.toISOString(),
          },
        };
      });
    },

    onValueChanged: ({ currentTarget: { value, id, valueAsNumber } }: React.SyntheticEvent<HTMLInputElement>) => {
      switch (id) {
        case 'formulations':
        case 'additional_libraries': {
          let val: string[];
          if (value.length === 0) {
            val = [];
          } else if (value.indexOf(',') === -1) {
            val = [value];
          } else {
            val = value.split(',');
          }

          setUserFeature(prev => {
            if (prev) {
              if (id === 'formulations') {
                prev.options.plan.features.formulations = val;
              } else {
                prev.options.plan.additional_libraries = val;
              }
              return { ...prev };
            }
          });
          break;
        }
        case 'effective_til': {
          const effective_til = moment(value);
          if (effective_til.year() < 3000) {
            setUserFeature(prev => {
              if (prev) {
                prev.options.expires_at = effective_til.toISOString();
                return { ...prev };
              }
            });
          }
          break;
        }

        case 'max_products':
        case 'max_organizations':
        case 'max_distinct_users_subscriptions': {
          setUserFeature(prev => {
            if (prev && valueAsNumber) {
              prev.options.plan[id] = valueAsNumber;
              return { ...prev };
            }
          });
          break;
        }
      }
    },

    userFeature,
  };
}
export default function PaidSubscriptionQuad(props: {
  feature: Feature<any>;
  userFeature?: Feature<VariableCloudFeatureOptions>;
  onSubscribeUser: (feature: Feature<VariableCloudFeatureOptions>) => void;
  onRemoveFeature: (feature: Feature<VariableCloudFeatureOptions>) => void;
  onClose: () => void;
}) {
  const { userFeature, onPlanChange, onValueChanged, handleAccessCheckChange, handleFeatureCheckChange } =
    useVariableCloudFeature(props.userFeature);
  return (
    <ModuleSegment title="Manage Variable Cloud Subscription">
      <PaidSubscriptionOptions
        onPlanChange={onPlanChange}
        userFeature={userFeature}
        onValueChanged={onValueChanged}
        onAccessControlCheckChange={handleAccessCheckChange}
        onFeatureCheckChange={handleFeatureCheckChange}
      />

      <FlexColumns justifyContent="flex-end">
        {userFeature && (
          <Button
            outline
            style={{ marginTop: 12 }}
            text="Delete Subscription"
            color={theme.button.cancelColor}
            onClick={() => props.onRemoveFeature(userFeature)}
          />
        )}
        {userFeature && (
          <Button
            style={{ marginTop: 12 }}
            text="Save Subscription"
            color={theme.button.actionColor}
            onClick={() => props.onSubscribeUser(userFeature)}
          />
        )}

        <Button style={{ marginTop: 12 }} text="Cancel" color={theme.button.neutralColor} onClick={props.onClose} />
      </FlexColumns>
    </ModuleSegment>
  );
}
