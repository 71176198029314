import * as React from 'react';
import { useHistory } from 'react-router';

import { Palette } from '@api/appdata/model';
import {
  FlexRows,
  LabeledInput,
  LargeButton,
  Loading,
  Modal,
  ModuleSegment,
  withUser,
  withUserViewProps,
} from '../common';
import vpapi from '@api/vpapi';
import theme from '@theme';
import appData from '@api/appdata';
import routes from '@utils/route-utils';
import config from '../../config';
import { Divider } from 'semantic-ui-react';

type ExternalProps = {
  onPaletteUpdate: (palette: Palette) => void | any;
  onPaletteDelete: (palette: Palette) => void | any;
  palette: Palette;
};
type ViewProps = ExternalProps &
  withUserViewProps & {
    history: ReturnType<typeof useHistory>;
  };

type ViewState = {
  loading?: string;
};

class PaletteDetailModal extends React.Component<ViewProps, ViewState> {
  state = {
    loading: undefined,
  };

  handleNameChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { palette } = this.props;
    palette.name = e.currentTarget.value;

    this.handleSave();
  };

  handleSave = () => {
    const { palette, onPaletteUpdate } = this.props;
    this.forceUpdate(() => {
      onPaletteUpdate?.(palette);
    });
  };
  handleAseDownload = () => {
    const { palette } = this.props;
    const palette_item_ids = palette.paletteItems.map(p => p.uuid);
    appData.downloadASE(palette_item_ids).then(({ export_id }) => {
      window.open(config.app_data.ase_file_download_url(export_id), '_blank');
    });
  };

  handleClose = () => {
    const { history } = this.props;
    history.goBack();
  };

  _delete = async () => {
    const { palette, onPaletteDelete } = this.props;
    onPaletteDelete(palette);
  };

  _export = async () => {
    const { palette, history, user } = this.props;
    try {
      this.setState({ loading: 'Creating Product Library' });
      const productGroup = await vpapi.productGroups.addSingle({ name: palette.name }, user.email);

      this.setState({
        loading: 'Migrating saved colors into products. (Do not close this window)',
      });
      await appData.migratePalette({
        palette_ids: [palette.uuid],
        product_group_id: productGroup.dbid,
        update_library: true,
      });

      setTimeout(() => {
        this.setState({ loading: undefined });
        history.push(routes.products(productGroup.dbid));
      }, 2 * 1000);
    } catch (err /**ApiError */) {
      console.log(err);
    }
  };

  DownloadAseFormat = () => (
    <LargeButton
      color={theme.button.alternativeActionColor}
      onClick={this.handleAseDownload}
      text="Download ASE Format"
      iconSrc="/icons/ase.png"
      css={{ filter: 'brightness(0) invert(1);' }}
    />
  );

  ExportButton = () => {
    const { user } = this.props;
    if (!user.variableCloudSubscription) {
      return null;
    }
    return (
      <LargeButton
        color={theme.button.alternativeActionColor}
        iconSrc="/product_libraries.svg"
        onClick={this._export}
        text="Create Product Library"
      />
    );
  };

  Content = () => {
    const { loading } = this.state;
    if (loading) {
      return <Loading text={loading} />;
    }

    const { palette } = this.props;
    const { ExportButton, DownloadAseFormat } = this;
    return (
      <FlexRows>
        <LabeledInput inputProps={{ defaultValue: palette.name }} label="name" onChange={this.handleNameChange} />

        <LabeledInput
          inputProps={{
            disabled: true,
            value: new Date(palette.locallyCreatedAt).toLocaleDateString(),
          }}
          label="created on"
        />

        <LabeledInput
          inputProps={{
            disabled: true,
            value: new Date(palette.locallyUpdatedAt).toLocaleDateString(),
          }}
          label="updated on"
        />

        <Divider text="ACTIONS" />
        <ExportButton />
        <DownloadAseFormat />

        <LargeButton
          color={theme.button.alternativeActionColor}
          iconSrc="/icons/delete.png"
          onClick={this._delete}
          text="Delete Palette"
        />
      </FlexRows>
    );
  };
  render = () => {
    const { palette } = this.props;
    const { Content } = this;
    return (
      <Modal modalProps={{ defaultOpen: true, onClose: this.handleClose }}>
        <ModuleSegment title={palette.name}>
          <Content />
        </ModuleSegment>
      </Modal>
    );
  };
}

export default withUser<ExternalProps>(props => {
  const history = useHistory();
  return <PaletteDetailModal {...props} history={history} />;
});
