import _ from 'lodash';
import { AnyLabJSON, LabColor } from '@variablecolor/colormath';
import MeasuredColor, { MeasuredColorJSON } from './MeasuredColor';

const BATCH_ADJUSTED = 'adj';

export interface DBProductCompositionDetailJSON {
  created_at?: string;
  updated_at?: string;
  percent_composition: number; // 0 - 1
  adjusted_lab: AnyLabJSON;
  name?: string;
  hex: string;
  rgb?: number;
  measured_colors?: MeasuredColorJSON[] /*DBMeasuredColorJSON*/;
  entered_by?: string;
}

class ProductCompositionDetail implements DBProductCompositionDetailJSON {
  percent_composition: number;

  measured_colors: MeasuredColor[];

  adjusted_lab: LabColor;

  id: string;

  hex: string;

  rgb: number;

  created_at: string;

  updated_at: string;

  entered_by?: string;

  name?: string;

  constructor(json: DBProductCompositionDetailJSON) {
    this.hex = json.hex;
    this.rgb = json.rgb || 0;
    this.percent_composition = json.percent_composition;
    this.name = json.name;
    this.entered_by = json.entered_by || undefined;

    this.adjusted_lab = LabColor.fromJSON(json.adjusted_lab);

    this.measured_colors = (json.measured_colors || []).map(mc => new MeasuredColor(mc));

    this.id = _.uniqueId('compdetail_');

    this.created_at = json.created_at ?? new Date().toISOString();
    this.updated_at = json.updated_at ?? this.created_at;
  }

  get colorMuseProScan() {
    const { measured_colors } = this;
    return measured_colors.find(mc => {
      const m = parseInt(mc.model, 10);
      return m >= 12.0 && m < 13.0;
    });
  }

  get spectroSCIScan() {
    return this.measured_colors.find(mc => mc.model === '11.1');
  }

  get spectroSCEScan() {
    return this.measured_colors.find(mc => mc.model === '11.0');
  }

  get colorMuseScan() {
    return this.measured_colors.find(mc => {
      const m = parseInt(mc.model, 10);
      return m < 10;
    });
  }

  get spectroScan() {
    return this.measured_colors.find(mc => {
      const m = parseInt(mc.model, 10);
      return m >= 11.0 && m < 12.0;
    });
  }

  isEditable() {
    return this.measured_colors.length === 0;
  }

  static NewFromLab = (lab: AnyLabJSON, entered_by?: string) => {
    const cmLab = LabColor.fromJSON(lab);

    const cd = new ProductCompositionDetail({
      adjusted_lab: lab,
      entered_by,
      hex: cmLab.hex(),
      percent_composition: 1,
    });

    const rgb = cmLab.toRGB();
    const rgbInt = (rgb.r << (16 + rgb.g)) << (16 + rgb.b);
    cd.rgb = rgbInt;

    return cd;
  };
}

export default {
  BATCH_ADJUSTED,
  ProductCompositionDetail,
};

export { ProductCompositionDetail, BATCH_ADJUSTED };
