import React from 'react';
import { DropdownProps, PrimaryText } from '@components/common';
import moment from 'moment';
import type { Feature, VariableCloudFeatureOptions } from '@api/cas/models';
import { Checkbox, Dropdown, FlexColumns, FlexRows, InputLabel, LabeledInput } from '../../common';
import { Optional } from '@app/utils/types';
import { OnInputChangeFunc } from '@app/components/common/Input/Input';
import theme from '@app/theme';

type ViewProps = {
  userFeature?: Feature<VariableCloudFeatureOptions>;
  onValueChanged: OnInputChangeFunc;
  onPlanChange: (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void | any;
  onFeatureCheckChange: (
    x: 'bulk_edit' | 'vizualizations' | 'package_codes',
  ) => (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => void | any;
  onAccessControlCheckChange: (
    t: 'organizations' | 'product_groups',
  ) => (e: React.SyntheticEvent<HTMLElement>, isChecked: boolean) => void | any;
};

const planOptions = [
  { text: 'Variable Cloud Free', value: 'VC-FREE' },
  { text: 'Variable Cloud Basic', value: 'VC-BASIC' },
  { text: 'Variable Cloud Business', value: 'VC-BUSINESS' },
  { text: 'Variable Cloud Enterprise', value: 'VC-ENTERPRISE' },
];

function formatDate(date?: string): Optional<string> {
  return date ? moment(date).format('YYYY-MM-DD') : undefined;
}

export default function ({
  userFeature,
  onValueChanged,
  onPlanChange,
  onFeatureCheckChange,
  onAccessControlCheckChange,
}: ViewProps) {
  return (
    <FlexRows>
      <FlexColumns style={{ alignContent: 'center' }}>
        <InputLabel labelStyle={{ width: 250 }} containerStyle={{ flexGrow: 0 }} label="Select Plan" />
        <Dropdown
          style={{ flexGrow: 1 }}
          selection
          value={userFeature?.options.plan.id}
          options={planOptions}
          onChange={onPlanChange}
        />
      </FlexColumns>

      {userFeature && (
        <LabeledInput
          inputProps={{
            type: 'date',
            value: formatDate(userFeature?.options.expires_at),
          }}
          id="effective_til"
          label="Effective Til"
          onChange={onValueChanged}
        />
      )}

      {userFeature && (
        <div>
          <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
            <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}> Redemption Details (View Only)</PrimaryText>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
          </FlexColumns>
        </div>
      )}
      {userFeature && (
        <LabeledInput
          inputProps={{
            disabled: true,
            type: 'date',
            value: formatDate(userFeature?.options.activated_at),
          }}
          id="activated_at"
          label="Activated At"
          onChange={onValueChanged}
        />
      )}

      {userFeature && (
        <LabeledInput
          inputProps={{
            disabled: true,
            type: 'text',
            value: userFeature?.options.redemption_code,
          }}
          id="redemption_code"
          label="Redemption Code"
          onChange={onValueChanged}
        />
      )}

      {userFeature && userFeature?.options && (
        <div>
          <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
            <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}> Plan Options (Admin Overrides)</PrimaryText>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
          </FlexColumns>

          <LabeledInput
            inputProps={{
              placeholder: 'no additional libraries specified. (use , between multiple product library ids)',
              type: 'text',
              value: userFeature!.options.plan.additional_libraries.join(','),
            }}
            label="Additional Libraries"
            id="additional_libraries"
            onChange={onValueChanged}
          />

          <LabeledInput
            inputProps={{ type: 'number', value: userFeature!.options.plan.max_products }}
            label="Max Products"
            id="max_products"
            onChange={onValueChanged}
          />
          <LabeledInput
            inputProps={{ type: 'number', value: userFeature!.options.plan.max_organizations }}
            label="Max Organizations"
            id="max_organizations"
            onChange={onValueChanged}
          />
          <LabeledInput
            inputProps={{ type: 'number', value: userFeature!.options.plan.max_distinct_users_subscriptions }}
            label="Max Seats"
            id="max_distinct_users_subscriptions"
            onChange={onValueChanged}
          />

          <div style={{ margin: 24 }} />

          <FlexColumns>
            <Checkbox
              onCheckChange={onAccessControlCheckChange('organizations')}
              checked={userFeature!.options.plan.access_control.organizations}
              text="Enable Organizational Sharing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onAccessControlCheckChange('product_groups')}
              checked={userFeature!.options.plan.access_control.product_groups}
              text="Enable Product Library Sharing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('bulk_edit')}
              checked={userFeature!.options.plan.features.bulk_edit}
              text="Enable Bulk Editing"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('vizualizations')}
              checked={userFeature!.options.plan.features.vizualizations}
              text="Enable Color Vizualizations (3D Graphs)"
            />

            <div style={{ margin: 12 }} />

            <Checkbox
              onCheckChange={onFeatureCheckChange('package_codes')}
              checked={userFeature!.options.plan.features.package_codes}
              text="Enable App Package Codes"
            />
          </FlexColumns>
        </div>
      )}
    </FlexRows>
  );
}
