import * as React from 'react';
import { List } from 'semantic-ui-react';

import qs from 'query-string';
import { NavLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import ProductGroupsList from './ProductGroupsList';

import type { ApiResponse, AggregationResult, ApiProductGroupSearch } from '@api/vpapi/productGroupsAPI';
import vpapi from '@api/vpapi';
import {
  AlternateText,
  Button,
  FlexRows,
  Loading,
  Modal,
  ModuleSegment,
  NoProductLibraryContent,
  PrimaryText,
  useScroll,
  DropdownProps,
} from '../common';
import theme from '@theme';
import routes from '@utils/route-utils';
import { ProductGroup } from '@api/vpapi/models';
import User from '@api/cas/user';
import FilterSection from './FilterSection';
import { Optional } from '@app/utils/types';
import { Role } from '@api/cas/models';

const ModuleHelpInfo = {
  Content: () => (
    <div>
      <AlternateText>
        <p>
          Product libraries group products within your organization. There can be multiple product libraries within an
          organization. A company can choose to have all products in a single library, or have multiple libraries that
          group products according to certain criteria, like product category, collection, color family, and more.
        </p>

        <PrimaryText fontWeight="bold">Creating a Product Library</PrimaryText>
        <List bulleted>
          <List.Item>Name your product library</List.Item>
          <List.Item>Import Products by uploading a CSV file, or choosing one of your Saved Folders</List.Item>

          <List.Item>
            Assign attributes to your products to make them searchable within Variable Cloud compatible app.
          </List.Item>
          <List.Item>
            Ensure color accuracy, using the product management feature in a compatible app to scan your products.
          </List.Item>
        </List>
      </AlternateText>
    </div>
  ),
  title: 'What are product libraries?',
};

type ViewProps = {
  user: User;

  // when undefined, will auto-navigate
  onProductGroupClick?: (productGroup: ProductGroup) => void;
};

const PAGE_SIZE = 50;

export function useQueryParams() {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search, { parseNumbers: true }) as any;
  const [filter, setFilter] = React.useState<ApiProductGroupSearch>();

  // Ensure Defaults are in the Query Params.
  React.useEffect(() => {
    if (!params.sort) {
      history.replace(
        `${location.pathname}?${qs.stringify({
          from: 0,
          size: PAGE_SIZE,
          sort: 'updated_at:desc',
          vendor: '',
          search: '',
          permission_filter: null,
        })}`,
      );
    }
  });

  React.useEffect(() => {
    if (
      !filter ||
      filter.permission_filter !== params.permission_filter ||
      filter.sort !== params.sort ||
      filter.vendor !== params.vendor ||
      filter.search !== params.search
    ) {
      setFilter({
        from: 0,
        size: PAGE_SIZE,
        sort: params.sort ?? 'updated_at:desc',
        vendor: params.vendor ?? null,
        search: params.search,
        permission_filter: params.permission_filter ?? null,
      });
    }
  }, [params, filter]);

  return {
    filter,
    onSearchTermSubmit: React.useCallback(
      (x: string) => {
        history.replace(`${location.pathname}?${qs.stringify({ ...filter, search: x })}`);
      },
      [history, filter, location],
    ),

    onRoleFilterClick: React.useCallback(
      (_: any, { value }: { value: Role | null }) => {
        history.replace(`${location.pathname}?${qs.stringify({ ...filter, permission_filter: value })}`);
      },
      [history, filter, location],
    ),

    onDirectionChange: React.useCallback(() => {
      const x = filter!.sort!.split(':');

      history.replace(
        `${location.pathname}?${qs.stringify({ ...filter, sort: `${x[0]}:${x[1] === 'asc' ? 'desc' : 'asc'}` })}`,
      );
    }, [history, filter, location]),

    onSortChange: React.useCallback(
      (_e: React.SyntheticEvent, data: DropdownProps) => {
        history.replace(
          `${location.pathname}?${qs.stringify({ ...filter, sort: `${data.value}:${filter!.sort!.split(':')[1]}` })}`,
        );
      },
      [history, filter, location],
    ),

    onVendorChange: React.useCallback(
      (_evt: React.SyntheticEvent, data: DropdownProps) => {
        history.replace(`${location.pathname}?${qs.stringify({ ...filter, vendor: data.value })}`);
      },
      [history, filter, location],
    ),

    clearVendor: React.useCallback(() => {
      history.replace(`${location.pathname}?${qs.stringify({ ...filter, vendor: '' })}`);
    }, [history, filter, location]),
  };
}

function useProductGroups() {
  const { filter } = useQueryParams();
  const [productGroups, setProductGroups] = React.useState<Optional<ApiResponse<ProductGroup>>>();
  const [vendors, setVendors] = React.useState<Optional<AggregationResult<string>[]>>();

  const loadProductGroups = React.useCallback(
    (prevPage: number = 0, _nextPage: number, itemsPerPage: number = PAGE_SIZE) => {
      vpapi.productGroups
        .getAll({
          ...filter,
          size: itemsPerPage,
          from: prevPage * PAGE_SIZE,

          with_products: -1,
        })
        .then(res => setProductGroups({ count: res.count, docs: (productGroups?.docs || []).concat(res.docs) }))
        .catch(e => alert(e));
    },
    [setProductGroups, filter, productGroups],
  );

  React.useEffect(() => {
    if (!vendors) {
      vpapi.productGroups
        .getVendors({ from: 0, size: 500 })
        .then(x => setVendors(x.docs))
        .catch(() => {
          setVendors(undefined);
        });
    }
  }, [vendors]);

  React.useEffect(() => {
    if (filter) {
      setProductGroups(undefined);
      vpapi.productGroups
        .getAll(filter)
        .then(setProductGroups)
        .catch(e => alert(e));
    }
  }, [filter]);

  const { ref: iScroll } = useScroll(productGroups?.count || 0, PAGE_SIZE, loadProductGroups);

  return { vendors, productGroups, iScroll, filter };
}

function ProductGroupsModal({ children }: { children: React.ReactElement }) {
  const history = useHistory();
  const buttons = [
    <NavLink to="/product-groups/_create" key="create_library">
      <Button color={theme.button.createColor} text="Create Library" />
    </NavLink>,
  ];

  return (
    <Modal modalProps={{ defaultOpen: true, onClose: history.goBack, size: 'large' }}>
      <ModuleSegment
        title={ModuleSegment.ModuleTitle('Product Libraries', 'Click for help')}
        buttons={buttons}
        helpModal={ModuleHelpInfo}
        containerStyle={{ minWidth: 600, height: '85vh' }}
      >
        {children}
      </ModuleSegment>
    </Modal>
  );
}
/* Primary component */
export default function ProductGroups({ onProductGroupClick, user }: ViewProps) {
  const history = useHistory();
  const { iScroll, filter, vendors, productGroups } = useProductGroups();

  const handleGroupClick = React.useCallback(
    (pg: ProductGroup) => {
      if (onProductGroupClick) {
        onProductGroupClick(pg);
        return;
      }

      history.push(routes.products(pg.dbid));
    },
    [history, onProductGroupClick],
  );

  if (!user.variableCloudSubscription) {
    return <Redirect to="/contact" />;
  }

  // return loading screen and nothing else if we are doing initial load
  if (!productGroups) {
    return (
      <ProductGroupsModal>
        <Loading text="Loading product libraries..." />
      </ProductGroupsModal>
    );
  }

  // Show indication of no library content has been created.
  if (
    productGroups.count === 0 &&
    filter?.search === '' &&
    (!filter?.permission_filter || filter.permission_filter === 'is_owner')
  ) {
    return (
      <ProductGroupsModal>
        <FlexRows style={{ margin: 'auto' }}>
          <NoProductLibraryContent title="No Product Libraries found" />
        </FlexRows>
      </ProductGroupsModal>
    );
  }

  return (
    <ProductGroupsModal>
      <div
        ref={iScroll}
        style={{
          maxHeight: '75vh',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <FlexRows>
          {/* <Debug data={productGroups} /> */}

          <FilterSection vendors={vendors} />
          <ProductGroupsList productGroups={productGroups.docs} onProductGroupClick={handleGroupClick} />
        </FlexRows>
      </div>
    </ProductGroupsModal>
  );
}
