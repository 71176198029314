import React from 'react';
import _ from 'lodash';

import { Feature, UserSearchJSON } from '@api/cas/models';
import { AlternateText, Button, FlexColumns, Modal, ModuleSegment, SystemAdminOnly } from '../../common';
import cas from '@api/cas';
import UserFeatureItem from '../UserFeatureItem';
import ColorScanAnalytics from '../ColorScanAnalytics';
import Analytics from './Analytics';
import Profile from './Profile';
import { useHistory } from 'react-router';
import theme from '@app/theme';

type ViewProps = {
  user: UserSearchJSON;
};
type onFeatureChange = (f: Feature<any>) => any;
function renderFeature<T>(
  user: UserSearchJSON,
  handleFeatureChange: onFeatureChange,
  handleAdd: onFeatureChange,
  handleRemove: onFeatureChange,
) {
  return (feature: Feature<T>) => {
    const userFeature = user.user_features.find(f => f.id === feature.id) as Feature<T>;
    return (
      <UserFeatureItem
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        handleFeatureChange={handleFeatureChange}
        key={_.uniqueId('_')}
        feature={feature}
        userFeature={userFeature}
      />
    );
  };
}

function useDetails(user: UserSearchJSON) {
  const history = useHistory();
  const [showPasswordDialog, setShowDialog] = React.useState(false);

  const [s, update] = React.useState(' ');
  const removeFeature = React.useCallback(
    async (feature: Feature<any>) => {
      user.user_features = user.user_features.filter(f => f.id !== feature.id);

      await cas.users.updateFeatures(user.user.email, user.user_features);

      update(s + s);
    },
    [user, s, update],
  );
  const addFeature = React.useCallback(
    async (feature: Feature<any>) => {
      user.user_features = [feature, ...user.user_features.filter(f => f.id !== feature.id)];

      await cas.users.updateFeatures(user.user.email, user.user_features);

      update(s + s);
    },
    [user, s, update],
  );

  const changeFeature = React.useCallback(
    async (feature: Feature<any>) => {
      const indexOf = user.user_features.findIndex(f => f.id === feature.id);
      if (indexOf === -1) {
        user.user_features.push(feature);
      } else {
        user.user_features.splice(indexOf, 1);
      }

      await cas.users.updateFeatures(user.user.email, user.user_features);

      update(s + s);
    },
    [user, s, update],
  );

  const [allFeatures, setAllFeatures] = React.useState<Feature<any>[]>([]);
  React.useEffect(() => {
    cas.fetchFeatures().then(setAllFeatures);
  }, [setAllFeatures]);

  return {
    availableFeatures: allFeatures.filter(({ options }) => !options.access || options.access !== 'package'),
    packageManagementFeatures: allFeatures.filter(
      ({ options }) => options.access && options.access === 'package' && !options.is_purchasable,
    ),
    addFeature,
    removeFeature,
    changeFeature,
    setShowDialog,
    showPasswordDialog,
    deleteAccount: React.useCallback(async () => {
      setShowDialog(false);

      cas.users.delete({ email: user.user.email });

      history.goBack();
    }, [user, history]),

    get moduleButtons() {
      return [
        <SystemAdminOnly key="delete_account">
          <Button
            text="Delete Account"
            outline
            color={theme.button.cancelColor}
            onClick={() => {
              setShowDialog(true);
            }}
          />
        </SystemAdminOnly>,
      ];
    },
  };
}
export default function UserDetailQuad({ user }: ViewProps) {
  const {
    deleteAccount,
    changeFeature,
    addFeature,
    removeFeature,
    availableFeatures,
    packageManagementFeatures,
    moduleButtons,
    showPasswordDialog,
    setShowDialog,
  } = useDetails(user);

  if (showPasswordDialog) {
    return (
      <Modal modalProps={{ defaultOpen: showPasswordDialog, onClose: () => setShowDialog(false) }}>
        <ModuleSegment title="Confirm User Deletion">
          <AlternateText>Please confirm before deleting {user.user.email} account, and all user data.</AlternateText>
          <FlexColumns style={{ justifyContent: 'flex-end' }}>
            <Button color={theme.button.cancelColor} type="submit" text="Delete User" onClick={deleteAccount} />
            <Button
              type="submit"
              color={theme.button.actionColor}
              outline
              text="Cancel"
              onClick={() => setShowDialog(false)}
            />
          </FlexColumns>
        </ModuleSegment>
      </Modal>
    );
  }

  return (
    <>
      <ModuleSegment title="User Details" buttons={moduleButtons}>
        <Profile user={user.user} />

        <SystemAdminOnly>
          <Analytics analytics={user.analytics} />
        </SystemAdminOnly>
      </ModuleSegment>

      <SystemAdminOnly>
        <div>
          <ModuleSegment title="Available Features">
            {availableFeatures.map(renderFeature(user, changeFeature, addFeature, removeFeature))}
          </ModuleSegment>

          <ModuleSegment
            title={ModuleSegment.ModuleTitle(
              'Available Package Management Features',
              'These feature enable administrators to grant access to their own App Packages',
            )}
          >
            {packageManagementFeatures.map(renderFeature(user, changeFeature, addFeature, removeFeature))}
          </ModuleSegment>
        </div>
      </SystemAdminOnly>

      <SystemAdminOnly>
        <div style={{ marginBottom: 16, marginTop: 16 }}>
          <ColorScanAnalytics email={user.user.email} />
        </div>
      </SystemAdminOnly>
    </>
  );
}
