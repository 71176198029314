import React from 'react';
import _ from 'lodash';
import { Button, Checkbox, FlexColumns, LabeledInput, Modal, ModuleSegment, PrimaryText } from '@components/common';
import { Feature, VariableCloudFeatureOptions } from '@api/cas/models';
import theme from '@app/theme';
import PaidSubscriptionQuad from './VariableCloudSubscriptionQuad';
import moment from 'moment';

export type FeatureListItemProps<T> = {
  handleFeatureChange(f: Feature<T>): void | any;
  handleAdd(f: Feature<T>): void | any;
  handleRemove(f: Feature<T>): void | any;
  feature: Feature<any>;
  userFeature?: Feature<T>;
};

type ModalType = 'purchase_modal' | 'variable_cloud_subscription' | null;
export default function FeatureCheckBox<T>({
  handleAdd,
  handleRemove,
  handleFeatureChange,
  feature,
  userFeature,
}: FeatureListItemProps<T>) {
  const [showModal, setShowModal] = React.useState<ModalType>(null);
  return (
    <>
      <VariableCloudSubscriptionModal
        feature={feature}
        userFeature={userFeature as Feature<VariableCloudFeatureOptions>}
        handleAdd={handleAdd as any}
        handleFeatureChange={handleFeatureChange as any}
        handleRemove={handleRemove as any}
        open={showModal === 'variable_cloud_subscription'}
        onClose={() => setShowModal(null)}
      />

      <PurchasableFeatureModal
        feature={feature}
        userFeature={userFeature}
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        handleFeatureChange={handleFeatureChange}
        open={showModal === 'purchase_modal'}
        onClose={() => setShowModal(null)}
      />
      <Checkbox
        onCheckChange={() => {
          if (feature.id === 'com.variable.features.VARIABLE_CLOUD') {
            setShowModal('variable_cloud_subscription');
            return;
          }

          if (!(feature.options as any)?.is_purchasable) {
            handleFeatureChange(_.cloneDeep(feature));
            return;
          }

          setShowModal('purchase_modal');
        }}
        checked={userFeature !== undefined}
        text={feature.name}
        containerStyle={{ marginRight: 16 }}
      />
    </>
  );
}

function VariableCloudSubscriptionModal({
  handleAdd,
  handleRemove,
  userFeature,
  feature,
  open,
  onClose,
}: FeatureListItemProps<VariableCloudFeatureOptions> & { onClose: () => void; open: boolean }) {
  return (
    <Modal modalProps={{ size: 'large', open, onClose }}>
      <PaidSubscriptionQuad
        onClose={onClose}
        feature={feature}
        onRemoveFeature={handleRemove}
        onSubscribeUser={handleAdd}
        userFeature={userFeature}
      />
    </Modal>
  );
}

function PurchasableFeatureModal<T>({
  feature,
  userFeature,
  handleAdd,
  handleRemove,
  open,
  onClose,
}: FeatureListItemProps<T> & { onClose: () => void; open: boolean }) {
  const activated_at = (userFeature?.options as any)?.activated_at;
  const expires_at = (userFeature?.options as any)?.expires_at;
  const redemption_code = (userFeature?.options as any)?.redemption_code;
  // Format activated_at string as a yyyy-MM-dd
  return (
    <Modal modalProps={{ size: 'large', open, onClose }}>
      <ModuleSegment
        containerStyle={{ padding: '3%' }}
        title={ModuleSegment.ModuleTitle(
          `Configure '${feature.name}' Feature`,
          'When no date is specified, user is granted a lifetime subscription',
        )}
      >
        <LabeledInput
          inputProps={{ type: 'date', defaultValue: expires_at ? moment(expires_at).format('YYYY-MM-DD') : undefined }}
          id="expires_at"
          label="Expires At"
          onChange={e => {
            if (feature) {
              (feature as any).options = {
                activated_at: new Date().toISOString(),
                ...(feature ?? {}).options,
                ...(userFeature ?? {}).options,
                expires_at: e.currentTarget.value,
              };
            }
          }}
        />

        {(redemption_code || activated_at) && (
          <FlexColumns alignItems="center" style={{ marginTop: 8 }}>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
            <PrimaryText style={{ marginLeft: 8, marginRight: 8 }}> Redemption Details</PrimaryText>
            <div style={{ height: 1, flex: 1, backgroundColor: theme.divider.background }} />
          </FlexColumns>
        )}

        {activated_at && (
          <LabeledInput
            inputProps={{ type: 'date', value: moment(activated_at).format('YYYY-MM-DD') }}
            id="activated_at"
            label="Activated At"
          />
        )}

        {redemption_code && (
          <LabeledInput inputProps={{ value: redemption_code }} id="redemption_code" label="Redemption Code" />
        )}

        <Button
          style={{ float: 'right' }}
          color={theme.button.actionColor}
          text="Save"
          onClick={() => {
            handleAdd(_.cloneDeep(feature));
            onClose();
          }}
        />
        <Button
          style={{ float: 'right' }}
          outline
          color={theme.button.cancelColor}
          text="Remove Feature"
          onClick={() => {
            handleRemove(feature);
            onClose();
          }}
        />
      </ModuleSegment>
    </Modal>
  );
}
