import { Illuminant, LabColor, Observer, ObserverT, Spectrum } from '@variablecolor/colormath';
import type { AnyLabJSON, IlluminantT } from '@variablecolor/colormath';
import modelUtils from '@api/models/ModelDetector';

import { ColorProvider, Scan } from '@api/appdata/model';
import { Optional } from '@app/utils/types';
import ModelDetector from '@api/models/ModelDetector';

export interface MeasuredColorJSON {
  lab?: AnyLabJSON;
  device_lab?: AnyLabJSON;
  device_batch?: string;
  batch: string;
  model: string;

  spectrum?: {
    start: number;
    step: number;
    curve: number[];
  };
  spectrum_labs?: AnyLabJSON[];

  // This is still used by colorcloudgo to project the model and batch  onto the MeasuredColor.
  measurement_device_class?: {
    id: number;
    device_type: 'spectro' | 'muse' | 'chroma' | string;
    model: string;
    batch: string;
    notes?: string;
  };

  measurement_device?: {
    measurement_device_uuid: string;
    trust_level: number;
  };

  raw_data: Optional<number[] | any>;

  created_at: string;
  scanner?: string;
}

export default class MeasuredColor implements ColorProvider, MeasuredColorJSON {
  batch: string;
  model: string;

  measurement_device_class?: {
    id: number;
    device_type: 'spectro' | 'muse' | 'chroma' | string;
    model: string;
    batch: string;
    notes?: string;
  };

  measurement_device?: {
    measurement_device_uuid: string;
    trust_level: number;
  };

  public readonly spectrum?: Spectrum;

  spectrum_labs?: LabColor[];

  readonly raw_data: Optional<any | number[]>;

  readonly lab: LabColor;

  scanner?: string;

  created_at: string;

  constructor(mcJSON: MeasuredColorJSON) {
    this.batch = mcJSON.device_batch || mcJSON.batch || mcJSON.measurement_device_class?.batch || '';
    this.model = mcJSON.model || mcJSON.measurement_device_class?.model || '4.0';
    this.created_at = mcJSON.created_at || new Date().toISOString();
    this.measurement_device = mcJSON.measurement_device;
    this.measurement_device_class = mcJSON.measurement_device_class;
    this.raw_data = mcJSON.raw_data;
    this.scanner = mcJSON.scanner;
    this.spectrum_labs = mcJSON.spectrum_labs?.map(LabColor.fromJSON);

    if (mcJSON.device_lab || mcJSON.lab) {
      this.lab = LabColor.fromJSON((mcJSON.device_lab || mcJSON.lab)!);
    } else {
      console.error('bad mc.lab format');
      throw new Error('bad mc.lab format');
    }

    if (mcJSON.spectrum) {
      this.spectrum = Spectrum.fromJSON(mcJSON.spectrum);
    }
  }

  static fromColorReading(c: Scan): MeasuredColor {
    if (c.spectrum) {
      return new MeasuredColor({
        raw_data: c,

        batch: c.batch,
        lab: c.spectrum.labColor(Illuminant.D50, Observer.TWO_DEGREE).toJSON(),
        measurement_device: {
          measurement_device_uuid: c.serial,
          trust_level: 100,
        },
        measurement_device_class: ModelDetector.measurementDeviceClass(c.model, c.batch),
        model: c.model || '11.0',
        spectrum: c.spectrum.toJSON(),
        spectrum_labs: c.spectrum.labsForAllIllums().map(x => x.toJSON()),

        created_at: new Date().toISOString(),
      });
    }

    return new MeasuredColor({
      raw_data: c.toServerJSON(),

      batch: c.batch,
      lab: c.labColor(),
      measurement_device: {
        measurement_device_uuid: c.serial,
        trust_level: 100,
      },
      measurement_device_class: ModelDetector.measurementDeviceClass(c.model, c.batch),

      model: c.model,

      spectrum: undefined,
      spectrum_labs: undefined,

      created_at: new Date().toISOString(),
    });
  }

  get isSpectro() {
    return modelUtils.isSpectro(this.model);
  }

  toAppDataJSON(): MeasuredColorJSON {
    return {
      batch: this.batch,
      model: this.model,

      lab: this.lab?.toAppDataJSON(),

      raw_data: this.raw_data,

      created_at: this.created_at,

      spectrum_labs: this.spectrum?.labsForAllIllums().map(lab => lab.toAppDataJSON()),
      spectrum: this.spectrum?.toJSON(),
    };
  }

  labColor = (ill: IlluminantT = Illuminant.D50, obs: ObserverT = Observer.TWO_DEGREE) => {
    return (
      this.spectrum?.labColor(ill, obs) ??
      this.spectrum_labs?.find(sl => sl.observer === obs && sl.illuminant === ill) ??
      this.lab.toLab(ill)
    );
  };
}
