import QueryString from 'query-string';
import _ from 'lodash';
import { EditableSearchRequest } from './models/Editables';
import { Optional } from '@app/utils/types';

function filterParamsFromAttrs(attrs: any, prefix: string) {
  const params = {};

  _.forOwn(attrs, (attrVals, attrKey) => {
    attrVals.forEach((val: any) => {
      const fKey = `${prefix}.${attrKey}`; //add `f.` before actual key
      (params as any)[fKey] = val;
    });
  });

  return params;
}
type SortDirection = 'asc' | 'desc';
class SearchConfig {
  searchTerm: string = '';

  onConfigChange: Optional<(x: SearchConfig) => void | any>;

  // Maps db keys to an array of filter values.
  filter = {
    attributes: {} as { [key: string]: string[] },
    cuuids: [] as string[],
  };

  p_attributes = {} as { [key: string]: string[] };

  sorts = {
    direction: 'asc' as SortDirection,
    name: 'updated_at',
  };

  /**** void onConfigChange(c) *****/
  constructor(onConfigChange?: (x: SearchConfig) => void | any) {
    this.onConfigChange = onConfigChange;
  }

  static fromParams = (onConfigChange?: (x: SearchConfig) => void | any, params?: any) => {
    const searchConfig = new SearchConfig(onConfigChange);
    searchConfig.update(params);
    return searchConfig;
  };

  prepareSortForApi = () => {
    return this.sorts.name + ':' + this.sorts.direction;
  };

  setSortBy = (name: string, direction: SortDirection) => {
    this.sorts.name = name;
    this.sorts.direction = direction;

    console.log('setSortBy', name, direction);
    if (this.onConfigChange) {
      this.onConfigChange(this);
    }
  };

  setSearchTerm = (term: string) => {
    this.searchTerm = term;

    this.onConfigChange?.(this);
  };

  get editableSearchReq(): EditableSearchRequest {
    const { cuuids: product_group_ids, attributes } = this.filter;
    const editables: any[] = Object.keys(attributes).map(key => ({
      items: attributes[key].map(value => ({
        is_selected: true,
        name: value,
      })),
      name: key,
      type: 'filters',
    }));
    return {
      editables,
      product_group_ids,
      search_term: this.searchTerm,
    };
  }
  /**
   *
   * Sets the values for the filter key.
   *
   * Invoked the onConfigChange callback
   *
   * @memberof SearchConfig
   */
  setSearchFilterValues = (key: string, newValues: string[]) => {
    this.filter.attributes[key] = newValues;

    this.onConfigChange?.(this);
  };

  update = (params: any) => {
    if (params.cuuids) {
      if (params.cuuids instanceof Array) {
        this.filter.cuuids = params.cuuids;
      } else {
        this.filter.cuuids.push(params.cuuids);
      }
    }

    this.searchTerm = params.search || '';

    Object.keys(params)
      .filter(k => k.startsWith('f.'))
      .forEach(key => {
        // Strip out the prefix to get the filter key name
        const filterKey = key.replace('f.', '');

        // Find the values array or create empty array for this filter key
        // Filter out all values that are not this value
        const currentValues = (this.filter.attributes[filterKey] || []).filter(v => v !== params[key]);

        // set the new values array for this filter key
        this.filter.attributes[filterKey] = [params[key], ...currentValues];
      });

    if (params.sort_name) {
      this.sorts.name = params.sort_name;
    }

    if (params.dir) {
      this.sorts.direction = params.dir;
    }
  };

  toQueryString = (page: number = -1) => {
    const params: any = {};

    if (page > -1) {
      params.page = page;
    }

    if (this.searchTerm) {
      params.search = this.searchTerm;
    }

    Object.assign(params, filterParamsFromAttrs(this.filter.attributes, 'f'));
    Object.assign(params, filterParamsFromAttrs(this.p_attributes, 'a'));

    this.filter.cuuids.forEach(id => {
      if (!params.cuuid) {
        params.cuuid = [];
      }

      params.cuuid.push(id);
    });

    if (this.sorts) {
      params.sort_name = this.sorts.name;
      params.dir = this.sorts.direction;
    }

    return QueryString.stringify(params);
  };

  toAPIQueryString = (page: number = -1) => {
    const params: any = {};

    if (page > -1) {
      params.page = page;
    }

    if (this.searchTerm) {
      params.search = this.searchTerm;
    }

    Object.assign(params, filterParamsFromAttrs(this.filter.attributes, 'f'));

    this.filter.cuuids.forEach(id => {
      if (!params.cuuid) {
        params.cuuid = [];
      }

      params.cuuid.push(id);
    });

    if (this.sorts) {
      params.sort = `${this.sorts.name}:${this.sorts.direction}`;
    }

    return QueryString.stringify(params);
  };
}

export default SearchConfig;
export { SearchConfig };
